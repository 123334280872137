
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #f0f2f5;
}

.login-card {
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.login-card .card-body {
    width: 400px;
    padding: 30px;
}

.text-center {
    text-align: center;
    margin-bottom: 30px;
    color: #0d6efd;
}

.error-msg {
    color: red;
    display: block;
    text-align: center;
    margin-bottom: 10px;
}
