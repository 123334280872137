.sidebar {
    height: 100%;
    position: fixed;
    left: 0;
    background-color: #222e3c;
    padding: 20px;
}

ul {
    list-style: none;
    padding: 0;
    color: white;
}

.menu-items > li > a{
    color: white;
}
