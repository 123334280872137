.sidebar {
    width: 15%;
    float: left;
}

.content {
    width: 85%;
    float: right;
    padding: 20px;
}

@media screen and (max-width: 600px) {
    .sidebar {
        display: none;
    }

    .content {
        width: 100%;
    }
}
