.display-flex {
    display: flex;
}

.display-flex input {
    margin-left: 8px;
    margin-right: 8px;
}

.productName{
    width: 100px;
}

.margin-10 {
    margin: 10px;
}

table {
    border-color: grey;
}

.float-right {
    float: right;
}

.color-red {
    color: red;
}