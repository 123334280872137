table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
}

table{
    width: 100%;
    margin-top: 50px;
    margin-bottom: 20px;
    padding-right: 100px;
    padding-left: 100px;
}

.background-danger, .background-danger:hover, .background-danger:focus {
    background-color: red;
}


@media print and (min-width: 480px) {
    body{
        margin: 1%;
        width: 100%;
        font-size: 24px;
    }

    div > header {
        display: none;
        height: 0;
    }

    .sidebar {
        visibility: hidden;    
    }

    div > h1, div > button{
        display: none;
    }

    .content {
        width: 100% !important;
    }

    .pagebreak {
        page-break-before: always;
    }

    .table {
        height: 600px;
    }

    .title {
        font-size: 32px;
    }

    .download-buttons {
        display: none;
    }
}